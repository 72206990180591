import React from "react"
import WebpImage from "../../WebpImage"
import {hubbleClassic} from "../../../services/data";

import "./style.scss"

const MilitaryValuePropsSection = () => {
  return (
    <div className="military-value-props grid-section">
      <div className="value-props-holder">
        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/01-Hubble-Contacts-Start-for-$1.png"
            alt=""
          />

          <p className="value-title">Start for $1</p>
          <p className="value-copy">
            Pay $1 for your first {hubbleClassic.trialPeriodPairs} pairs of our Classic lenses – or try Hydro
            or SkyHy by Hubble for 40% off your first order.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/02-Hubble-Contacts-No-Hassle-Policies.png"
            alt=""
          />

          <p className="value-title">No-Hassle Policies</p>
          <p className="value-copy">
            Not seeing the value? Just send it back.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/03-Hubble-Contacts-Cancel-Any-Time.png"
            alt="Cancel your subscription at any time"
          />

          <p className="value-title">Cancel at any Time</p>
          <p className="value-copy">
            Cancel your contact lens subscription at any time, for any reason.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/04-Hubble-Contacts-Eye-Health.png"
            alt=""
          />

          <p className="value-title">Eye Health & Safety</p>
          <p className="value-copy">
            All Hubble lenses are daily-wear contacts – the healthiest and
            safest type of lens.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/05-Hubble-Contacts-Secure-Payment.png"
            alt=""
          />

          <p className="value-title">Secure Payment</p>
          <p className="value-copy">
            Safety first across all payment platforms.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MilitaryValuePropsSection
